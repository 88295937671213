import React from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import googleCalendar from "@fullcalendar/google-calendar"
import locale_de from "@fullcalendar/core/locales/de"

class loadableCal extends React.Component {
  render() {
    return (
      <FullCalendar
        plugins={[dayGridPlugin, googleCalendar]}
        googleCalendarApiKey="AIzaSyCsatok_8kkQUkKQS26EEdxfKjUPNU0JxI"
        locales={[locale_de]}
        locale="de"
        timeZone="Europe/Berlin"
        header={{ center: "prev title next" }}
        buttonText={{
          next: "Nächster Monat",
          prev: "Vorheriger Monat",
          title: "Heute",
        }}
        events={{
          googleCalendarId:
            "opda1jhnhtn2cev9f83u4snh88@group.calendar.google.com",
        }}
        initialView="dayGridMonth"
      />
    )
  }
}

export default loadableCal
