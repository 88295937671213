import React from "react"
import Loadable from "react-loadable"
import loadableCal from "../components/loadableCal"
import DefaultLayout from "../components/default-page-layout"

const Loading = Loadable({
  loader: () => import("../components/loadableCal"),
  loading() {
    return <div>Loading</div>
  },
})

export default function Calendar() {
  return (
    <>
      <DefaultLayout>
        <Loading />
      </DefaultLayout>
    </>
  )
}
